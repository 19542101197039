import {Component, OnInit} from '@angular/core';
import {AppService} from '@services/app.service';
import {ModalPercepSaudeComponent} from '../Modal6-PercepSaude/modal-percepSaude.component';
import {FormControl, FormGroup} from '@angular/forms';
import {AnamneseService} from '@services/dashboard/anamnese/anamnese.service';
import {ModalCheckBoxes2Component} from "@components/dashboard/modais/Modal8-CheckBoxes2/modal-checkBoxes2.component";
import {Router} from "@angular/router";

@Component({
    selector: 'app-modal-saudEmocFunc',
    templateUrl: './modal-saudEmocFunc.component.html',
    styleUrls: ['./modal-saudEmocFunc.component.scss']
})
export class ModalSaudEmocFuncComponent implements OnInit {
    public erroAvancarAnamnese: string | any = ""
    public campoinvalido: string | any = ""
    public formGroupEtapa7: FormGroup
    public carregandoAtualizarAnamnese: boolean = false
    public opcoesDiasDaSemana = [0, 1, 2, 3, 4, 5, 6, 7]
    public desativarCampos: boolean = false
    public opcoesTempo = [{
        label: "Selecione", value: null
    }, {
        label: "00:00", value: "00:00:00"
    }, {
        label: "00:05", value: "00:05:00"
    }, {
        label: "00:10", value: "00:10:00"
    }, {
        label: "00:30", value: "00:30:00"
    }, {
        label: "00:40", value: "00:40:00"
    }, {
        label: "01:00", value: "01:00:00"
    }, {
        label: "01:05", value: "01:05:00"
    }, {
        label: "01:10", value: "01:10:00"
    }, {
        label: "01:30", value: "01:30:00"
    }, {
        label: "01:40", value: "01:40:00"
    }, {
        label: "02:00", value: "02:00:00"
    }, {
        label: "02:05", value: "02:05:00"
    }, {
        label: "02:10", value: "02:10:00"
    }, {
        label: "02:30", value: "02:30:00"
    }, {
        label: "02:40", value: "02:40:00"
    }, {
        label: "03:00", value: "03:00:00"
    }, {
        label: "03:05", value: "03:05:00"
    }, {
        label: "03:10", value: "03:10:00"
    }, {
        label: "03:30", value: "03:30:00"
    }, {
        label: "03:40", value: "03:40:00"
    }, {
        label: "04:00", value: "04:00:00"
    }, {
        label: "04:05", value: "04:05:00"
    }, {
        label: "04:10", value: "04:10:00"
    }, {
        label: "04:30", value: "04:30:00"
    }, {
        label: "04:40", value: "04:40:00"
    }, {
        label: "05:00", value: "05:00:00"
    }, {
        label: "05:05", value: "05:05:00"
    }, {
        label: "05:10", value: "05:10:00"
    }, {
        label: "05:30", value: "05:30:00"
    }, {
        label: "05:40", value: "05:40:00"
    }, {
        label: "06:00", value: "06:00:00"
    }, {
        label: "06:05", value: "06:05:00"
    }, {
        label: "06:10", value: "06:10:00"
    }, {
        label: "06:30", value: "06:30:00"
    }, {
        label: "06:40", value: "06:40:00"
    }, {
        label: "07:00", value: "07:00:00"
    }, {
        label: "07:05", value: "07:05:00"
    }, {
        label: "07:10", value: "07:10:00"
    }, {
        label: "07:30", value: "07:30:00"
    }, {
        label: "07:40", value: "07:40:00"
    }, {
        label: "08:00", value: "08:00:00"
    }, {
        label: "08:05", value: "08:05:00"
    }, {
        label: "08:10", value: "08:10:00"
    }, {
        label: "08:30", value: "08:30:00"
    }, {
        label: "08:40", value: "08:40:00"
    }, {
        label: "09:00", value: "09:00:00"
    }, {
        label: "09:05", value: "09:05:00"
    }, {
        label: "09:10", value: "09:10:00"
    }, {
        label: "09:30", value: "09:30:00"
    }, {
        label: "09:40", value: "09:40:00"
    }, {
        label: "10:00", value: "10:00:00"
    }, {
        label: "10:05", value: "10:05:00"
    }, {
        label: "10:10", value: "10:10:00"
    }, {
        label: "10:30", value: "10:30:00"
    }, {
        label: "10:40", value: "10:40:00"
    }, {
        label: "11:00", value: "11:00:00"
    }, {
        label: "11:05", value: "11:05:00"
    }, {
        label: "11:10", value: "11:10:00"
    }, {
        label: "11:30", value: "11:30:00"
    }, {
        label: "11:40", value: "11:40:00"
    }, {
        label: "12:00", value: "12:00:00"
    }, {
        label: "12:05", value: "12:05:00"
    }, {
        label: "12:10", value: "12:10:00"
    }, {
        label: "12:30", value: "12:30:00"
    }, {
        label: "12:40", value: "12:40:00"
    },]

    constructor(private appService: AppService, private anamneseService: AnamneseService, private router: Router) {
    }

    ngOnInit(): void {
        this.desativarCampos = this.router.url.includes('acesso-avaliador')
        const anamnese = this.anamneseService.getAnamnese()

        this.formGroupEtapa7 = new FormGroup({
            NumeroEtapa: new FormControl(anamnese?.Etapa7?.NumeroEtapa),
            AnamneseID: new FormControl(anamnese?.Etapa7?.AnamneseID),
            IpacDiasCaminhando: new FormControl({
                value: anamnese?.Etapa7?.IpacDiasCaminhando, disabled: this.desativarCampos
            }),
            IpacTempoCaminhando: new FormControl({
                value: anamnese?.Etapa7?.IpacTempoCaminhando, disabled: this.desativarCampos
            }),
            IpacDiasAtivModerada: new FormControl({
                value: anamnese?.Etapa7?.IpacDiasAtivModerada, disabled: this.desativarCampos
            }),
            IpacTempoAtivModerada: new FormControl({
                value: anamnese?.Etapa7?.IpacTempoAtivModerada, disabled: this.desativarCampos
            }),
            IpacDiasAtivVigorosa: new FormControl({
                value: anamnese?.Etapa7?.IpacDiasAtivVigorosa, disabled: this.desativarCampos
            }),
            IpacTempoAtivVigorosa: new FormControl({
                value: anamnese?.Etapa7?.IpacTempoAtivVigorosa, disabled: this.desativarCampos
            }),
            IpacTempoTrabalhoDia: new FormControl({
                value: anamnese?.Etapa7?.IpacTempoTrabalhoDia, disabled: this.desativarCampos
            }),
            IpacTempoTransporteDia: new FormControl({
                value: anamnese?.Etapa7?.IpacTempoTransporteDia, disabled: this.desativarCampos
            }),
            IpacTempoTvDia: new FormControl({value: anamnese?.Etapa7?.IpacTempoTvDia, disabled: this.desativarCampos}),
            IpacTempoComputadorDia: new FormControl({
                value: anamnese?.Etapa7?.IpacTempoComputadorDia, disabled: this.desativarCampos
            }),
            IpacTempoDescansoDia: new FormControl({
                value: anamnese?.Etapa7?.IpacTempoDescansoDia, disabled: this.desativarCampos
            }),
            IpacTempoLeituraDia: new FormControl({
                value: anamnese?.Etapa7?.IpacTempoLeituraDia, disabled: this.desativarCampos
            }),
            IpacTempoTrabalhoFDS: new FormControl({
                value: anamnese?.Etapa7?.IpacTempoTrabalhoFDS, disabled: this.desativarCampos
            }),
            IpacTempoTransporteFDS: new FormControl({
                value: anamnese?.Etapa7?.IpacTempoTransporteFDS, disabled: this.desativarCampos
            }),
            IpacTempoTvFDS: new FormControl({value: anamnese?.Etapa7?.IpacTempoTvFDS, disabled: this.desativarCampos}),
            IpacTempoComputadorFDS: new FormControl({
                value: anamnese?.Etapa7?.IpacTempoComputadorFDS, disabled: this.desativarCampos
            }),
            IpacTempoDescansoFDS: new FormControl({
                value: anamnese?.Etapa7?.IpacTempoDescansoFDS, disabled: this.desativarCampos
            }),
            IpacTempoLeituraFDS: new FormControl({
                value: anamnese?.Etapa7?.IpacTempoLeituraFDS, disabled: this.desativarCampos
            }),
            TotaisTempoSentado: new FormControl({
                value: anamnese?.Etapa7?.TotaisTempoSentado, disabled: this.desativarCampos
            }),
            IpacTempoTotalSentadoDia: new FormControl({
                value: anamnese?.Etapa7?.IpacTempoTotalSentadoDia, disabled: this.desativarCampos
            }),
            IpacTempoTotalSentadoFDS: new FormControl({
                value: anamnese?.Etapa7?.IpacTempoTotalSentadoFDS, disabled: this.desativarCampos
            }),

            DataInicio: new FormControl(new Date()),
            DataFim: new FormControl(null)
        })

    }

    handleClickFecharModal() {
        this.appService.closeModal()
    }

    handleClickVoltarModal() {
        this.appService.closeModal()
        this.appService.openModal(ModalPercepSaudeComponent, {size: 'lg', backdrop: false})
    }


    handleClickProximoModal() {

        // caso os campos esteja desativados,
        // significa que é o professor acessando a tela
        // E ele só pode visualizar as informacoes
        // Logo, a gente nao atualiza a anamnese, apenas
        // Redireciona para a nova tela
        if (this.desativarCampos) {
            this.appService.closeModal()
            this.appService.openModal(ModalCheckBoxes2Component)
            return
        }

        const anamnese = this.anamneseService.getAnamnese()
        const classeContexto = this

        this.carregandoAtualizarAnamnese = true

        const novaAnamneseAtualizada = {
            ...anamnese, Etapa7: {
                ...anamnese?.Etapa7, ...this.formGroupEtapa7.getRawValue(), DataFim: new Date(),
            }
        }

        // primeiro, validamos a anamnese, para ver se os campos obrigatórios foram todos preenchidos
        // Caso estejam preenchidos, deixamos avançar
        this.anamneseService.obterTempoSentado(novaAnamneseAtualizada).subscribe({
            next(arrayTempoTotal) {
                // @ts-ignore
                [novaAnamneseAtualizada.Etapa7.TempoTotalSentadoDia = "00:00", novaAnamneseAtualizada.Etapa7.TempoTotalSentadoFDS = "00:00"] = arrayTempoTotal

                // agora eu calculo o tempo total das atividades sentados. Essencial se não esse endpoint retorna um erro nessa etapa
                classeContexto.anamneseService.validarAnamnese(novaAnamneseAtualizada).subscribe({
                    next(response) {

                        // verifico se há etapas inválidas (endpoint retorna 200 mesmo pra dados inválidos)
                        // @ts-ignore
                        if (typeof response?.etapaInvalida?.mensagem == 'string' && response?.etapaInvalida?.etapa === 7) {
                            // @ts-ignore
                            classeContexto.erroAvancarAnamnese = response?.etapaInvalida?.mensagem
                            // @ts-ignore
                            classeContexto.campoinvalido = response?.etapaInvalida?.campo;
                            classeContexto.carregandoAtualizarAnamnese = false
                            return
                        }

                        // atualiza a anamnese se tudo estiver ok
                        classeContexto.anamneseService.putAnamnese(novaAnamneseAtualizada).subscribe({
                            next(response2) {

                                classeContexto.carregandoAtualizarAnamnese = false
                                classeContexto.anamneseService.setAnamnese(response2)
                                classeContexto.appService.closeModal()
                                classeContexto.appService.openModal(ModalCheckBoxes2Component)
                            }, error(response) {
                                classeContexto.carregandoAtualizarAnamnese = false
                                if (typeof response?.error?.Message == 'string') {
                                    classeContexto.erroAvancarAnamnese = response?.error?.Message
                                } else {
                                    classeContexto.erroAvancarAnamnese = "Ocorreu um erro ao atualizar a anamnese"
                                }
                            }
                        })
                    }, error(erro) {
                        classeContexto.carregandoAtualizarAnamnese = false
                        if (typeof erro?.Message == 'string') {
                            classeContexto.erroAvancarAnamnese = erro?.Message
                        } else {
                            classeContexto.erroAvancarAnamnese = "Ocorreu um erro ao validar as informações preenchidas"
                        }
                    }
                })

            }
        })
    }
}
