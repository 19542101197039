import {Component, OnInit} from '@angular/core';
import {AppService} from '@services/app.service';
import {ModalQuestFarmPrelimComponent} from '../Modal4-QuestFarmPrelim/modal-questFarmPrelim.component';
import {FormControl, FormGroup} from '@angular/forms';
import {AnamneseService} from '@services/dashboard/anamnese/anamnese.service';
import {ModalPercepSaudeComponent} from "@components/dashboard/modais/Modal6-PercepSaude/modal-percepSaude.component";
import {Router} from "@angular/router";

@Component({
    selector: 'app-modal-checkBoxes',
    templateUrl: './modal-checkBoxes.component.html',
    styleUrls: ['./modal-checkBoxes.component.scss']
})
export class ModalCheckBoxesComponent implements OnInit {
    public erroAvancarAnamnese: string | any = ""
    public carregandoAtualizarAnamnese: boolean = false
    public formGroupEtapa5: FormGroup
    public desativarCampos: boolean = false
    public campoinvalido: string | any = ""

    constructor(private appService: AppService, private anamneseService: AnamneseService, private router: Router) {
    }

    ngOnInit(): void {
        this.desativarCampos = this.router.url.includes('acesso-avaliador')
        const anamnese = this.anamneseService.getAnamnese()

        this.formGroupEtapa5 = new FormGroup({
            NumeroEtapa: new FormControl({value: anamnese?.Etapa5.NumeroEtapa, disabled: this.desativarCampos}),
            AnamneseID: new FormControl({value: anamnese?.Etapa5.AnamneseID, disabled: this.desativarCampos}),
            ProxIdeal: new FormControl({value: anamnese?.Etapa5.ProxIdeal, disabled: this.desativarCampos}),
            VidaExcelente: new FormControl({value: anamnese?.Etapa5.VidaExcelente, disabled: this.desativarCampos}),
            SatisfeitoVida: new FormControl({value: anamnese?.Etapa5.SatisfeitoVida, disabled: this.desativarCampos}),
            DesejeiVida: new FormControl({value: anamnese?.Etapa5.DesejeiVida, disabled: this.desativarCampos}),
            MudariaNada: new FormControl({value: anamnese?.Etapa5.MudariaNada, disabled: this.desativarCampos}),
            DataInicio: new FormControl({value: new Date(), disabled: this.desativarCampos}),
            DataFim: new FormControl(null),
        })

    }

    toggleCheckboxWidthValue(checkboxName: string, value: any) {
        this.formGroupEtapa5.get(checkboxName).setValue(value);
    }

    handleClickFecharModal() {
        this.appService.closeModal()
    }

    handleClickVoltarModal() {
        this.appService.closeModal()
        this.appService.openModal(ModalQuestFarmPrelimComponent, {size: 'lg', backdrop: false})
    }


    handleClickProximoModal() {
        // caso os campos esteja desativados,
        // significa que é o professor acessando a tela
        // E ele só pode visualizar as informacoes
        // Logo, a gente nao atualiza a anamnese, apenas
        // Redireciona para a nova tela
        if (this.desativarCampos) {
            this.appService.closeModal()
            this.appService.openModal(ModalPercepSaudeComponent)
            return
        }

        const anamnese = this.anamneseService.getAnamnese()
        const classeContexto = this

        this.carregandoAtualizarAnamnese = true

        const novaAnamneseAtualizada = {
            ...anamnese, Etapa5: {
                ...anamnese?.Etapa5, ...this.formGroupEtapa5.getRawValue(), DataFim: new Date(),
            }
        }

        // primeiro, validamos a anamnese, para ver se os campos obrigatórios foram todos preenchidos
        // Caso estejam preenchidos, deixamos avançar
        this.anamneseService.validarAnamnese(novaAnamneseAtualizada).subscribe({
            next(response) {
                // verifico se há etapas inválidas (endpoint retorna 200 mesmo pra dados inválidos)
                // @ts-ignore
                if (typeof response?.etapaInvalida?.mensagem == 'string' && response?.etapaInvalida?.etapa === 5) {
                    // @ts-ignore
                    classeContexto.erroAvancarAnamnese = response?.etapaInvalida?.mensagem
                      // @ts-ignore
                    classeContexto.campoinvalido = response?.etapaInvalida?.campo;
                    classeContexto.carregandoAtualizarAnamnese = false
                    return
                }

                // atualiza a anamnese se tudo estiver ok
                classeContexto.anamneseService.putAnamnese(novaAnamneseAtualizada).subscribe({
                    next(response2) {

                        classeContexto.carregandoAtualizarAnamnese = false
                        classeContexto.anamneseService.setAnamnese(response2)
                        classeContexto.appService.closeModal()
                        classeContexto.appService.openModal(ModalPercepSaudeComponent)
                    }, error(response) {
                        classeContexto.carregandoAtualizarAnamnese = false
                        if (typeof response?.error?.Message == 'string') {
                            classeContexto.erroAvancarAnamnese = response?.error?.Message
                        } else {
                            classeContexto.erroAvancarAnamnese = "Ocorreu um erro ao atualizar a anamnese"
                        }
                    }
                })

            }, error(erro) {
                classeContexto.carregandoAtualizarAnamnese = false
                if (typeof erro?.Message == 'string') {
                    classeContexto.erroAvancarAnamnese = erro?.Message
                } else {
                    classeContexto.erroAvancarAnamnese = "Ocorreu um erro ao validar as informações preenchidas"
                }
            }
        })
    }
}
