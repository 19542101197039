<div class="modal-biomarcador">
    <div class="modal-backdrop"></div>

    <header class="bg-light-blue modal-biomarcador-header d-flex">
        <div
            style="flex: 1"
            class="d-flex justify-content-center align-items-center"
        >
            <div>
                <div>
                    <span class="modal-biomarcador-header-safe"
                        >Biomarcadores SAFE®</span
                    >
                </div>
                <div>
                    <span
                        class="text-yellow modal-biomarcador-header-tipo-biomarcador"
                    >
                        Reta Final
                    </span>
                </div>
            </div>
            <div style="padding-left: 20px">
                <img src="assets/svg/biomarcador-1.svg" alt="" />
            </div>
        </div>

        <div
            style="flex: 1"
            class="d-flex justify-content-end align-items-center"
        >
            <div class="identificador-pagina">
                <span class="text-yellow">10/</span>
                <span class="text-light">10</span>
            </div>
        </div>

        <div class="fechar-modal" (click)="handleClickFecharModal()">
            <img src="assets/svg/close-icon.svg" alt="" />
        </div>
    </header>
    <div class="modal-bio-form bg-light p-4">
        <h1 class="form-title">Preencha os Dados</h1>
        <div class="row vertical-content">
            <form class="form-form p-3" [formGroup]="formGroupEtapa10">
                <div class="col-md-12 row">
                    <div class="col-12">
                        <span>
                            Usando como guia a escala apresentada a seguir,
                            marque a opção ao lado de cada afirmativa, para
                            indicar o quanto você concorda com ela.
                        </span>
                    </div>
                    <div class="col-md-6 mt-3">
                        <div
                            [ngClass]="{
                                'input-error': campoinvalido === 'AgradecerVida'
                            }"
                        >
                            <span class="form-label-texts">
                                Tenho muito o que agradecer na vida.
                            </span>

                            <div
                                class="mt-2 d-flex align-items-center"
                                *ngFor="let opcao of opcoesConcordancia"
                            >
                                <input
                                    formControlName="AgradecerVida"
                                    type="radio"
                                    [value]="opcao.value"
                                    class="form-input-checkbox"
                                    [checked]="
                                        formGroupEtapa10.get('AgradecerVida')
                                            .value == opcao.value
                                    "
                                />
                                <label class="form-label-texts-helper-check">{{
                                    opcao.label
                                }}</label>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 mt-3">
                        <div
                            [ngClass]="{
                                'input-error':
                                    campoinvalido === 'ListaMuitoLonga'
                            }"
                        >
                            <span class="form-label-texts">
                                Se eu fizesse uma lista de tudo o que tenho a
                                agradecer, seria uma lista muito longa.
                            </span>
                            <div
                                class="mt-2 d-flex align-items-center"
                                *ngFor="let opcao of opcoesConcordancia"
                            >
                                <input
                                    formControlName="ListaMuitoLonga"
                                    type="radio"
                                    [value]="opcao.value"
                                    class="form-input-checkbox"
                                    [checked]="
                                        formGroupEtapa10.get('ListaMuitoLonga')
                                            .value == opcao.value
                                    "
                                />
                                <label class="form-label-texts-helper-check">{{
                                    opcao.label
                                }}</label>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 mt-3">
                        <div
                            [ngClass]="{
                                'input-error':
                                    campoinvalido === 'MotivosAgradecer'
                            }"
                        >
                            <span class="form-label-texts">
                                Quando olho para o mundo, não vejo muitos
                                motivos para agradecer.
                            </span>
                            <div
                                class="mt-2 d-flex align-items-center"
                                *ngFor="let opcao of opcoesConcordancia"
                            >
                                <input
                                    formControlName="MotivosAgradecer"
                                    type="radio"
                                    [value]="opcao.value"
                                    class="form-input-checkbox"
                                    [checked]="
                                        formGroupEtapa10.get('MotivosAgradecer')
                                            .value == opcao.value
                                    "
                                />
                                <label class="form-label-texts-helper-check">{{
                                    opcao.label
                                }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mt-3">
                        <div
                            [ngClass]="{
                                'input-error': campoinvalido === 'MuitaGente'
                            }"
                        >
                            <span class="form-label-texts">
                                Sou grato(a) a muita gente.
                            </span>

                            <div
                                class="mt-2 d-flex align-items-center"
                                *ngFor="let opcao of opcoesConcordancia"
                            >
                                <input
                                    formControlName="MuitaGente"
                                    type="radio"
                                    [value]="opcao.value"
                                    class="form-input-checkbox"
                                    [checked]="
                                        formGroupEtapa10.get('MuitaGente')
                                            .value == opcao.value
                                    "
                                />
                                <label class="form-label-texts-helper-check">{{
                                    opcao.label
                                }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mt-3">
                        <div
                            [ngClass]="{
                                'input-error': campoinvalido === 'HistoriaVida'
                            }"
                        >
                            <span class="form-label-texts">
                                Com o passar dos anos, cada vez mais me sinto
                                inclinado (a) a apreciar as pessoas, os
                                acontecimentos e as situações que fazem parte da
                                história da minha vida.
                            </span>

                            <div
                                class="mt-2 d-flex align-items-center"
                                *ngFor="let opcao of opcoesConcordancia"
                            >
                                <input
                                    formControlName="HistoriaVida"
                                    type="radio"
                                    [value]="opcao.value"
                                    class="form-input-checkbox"
                                    [checked]="
                                        formGroupEtapa10.get('HistoriaVida')
                                            .value == opcao.value
                                    "
                                />
                                <label class="form-label-texts-helper-check">{{
                                    opcao.label
                                }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mt-3">
                        <div
                            [ngClass]="{
                                'input-error':
                                    campoinvalido === 'AlguemAlgumaCoisa'
                            }"
                        >
                            <span class="form-label-texts">
                                Pode passar muito tempo ainda, antes que eu me
                                sinta grato (a) a alguém ou alguma coisa.
                            </span>

                            <div
                                class="mt-2 d-flex align-items-center"
                                *ngFor="let opcao of opcoesConcordancia"
                            >
                                <input
                                    formControlName="AlguemAlgumaCoisa"
                                    type="radio"
                                    [value]="opcao.value"
                                    class="form-input-checkbox"
                                    [checked]="
                                        formGroupEtapa10.get(
                                            'AlguemAlgumaCoisa'
                                        ).value == opcao.value
                                    "
                                />
                                <label class="form-label-texts-helper-check">{{
                                    opcao.label
                                }}</label>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 mt-3">
                        <hr />
                    </div>
                    <div class="col-12 mt-3">
                        <h4 class="center">Perdão</h4>
                    </div>
                    <div class="col-12 mt-3 center">
                        <span class="form-label-texts-helper">
                            Indicar em relações anteriores o quanto você perdoou
                            seu parceiro ou pessoas de relacionamentos:
                        </span>
                    </div>

                    <div class="col-12">
                        <div
                            [ngClass]="{
                                'input-error': campoinvalido === 'Perdao'
                            }"
                        >
                            <select
                                formControlName="Perdao"
                                class="form-control form-input-box"
                            >
                                <option value="" selected="selected">
                                    Selecione
                                </option>
                                <option
                                    [ngValue]="1"
                                    [selected]="
                                        formGroupEtapa10.get('Perdao').value ===
                                        1
                                            ? 'selected'
                                            : ''
                                    "
                                >
                                    Eu nunca perdôo
                                </option>
                                <option
                                    [ngValue]="2"
                                    [selected]="
                                        formGroupEtapa10.get('Perdao').value ===
                                        2
                                            ? 'selected'
                                            : ''
                                    "
                                >
                                    Eu posso perdoá-lo
                                </option>
                                <option
                                    [ngValue]="3"
                                    [selected]="
                                        formGroupEtapa10.get('Perdao').value ===
                                        3
                                            ? 'selected'
                                            : ''
                                    "
                                >
                                    Eu estou tentando perdoá-lo
                                </option>
                                <option
                                    [ngValue]="4"
                                    [selected]="
                                        formGroupEtapa10.get('Perdao').value ===
                                        4
                                            ? 'selected'
                                            : ''
                                    "
                                >
                                    Eu perdôo-lhe
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="col-12 mt-3">
                        <hr />
                    </div>
                    <div class="col-12 mt-3">
                        <span class="form-label-texts">
                            Com o aumento da taxa de suicídio no mundo, existe
                            uma preocupação grande da OMS, com isso estamos
                            pesquisando pensamentos, tentativas e/ou convívio
                            com pessoas com pensamento suicida, portanto:
                        </span>
                    </div>
                    <div class="col-md-6 mt-3">
                        <div
                            [ngClass]="{
                                'input-error':
                                    campoinvalido === 'SuicidioPensou'
                            }"
                        >
                            <span class="form-label-texts">
                                Você já pensou em suicídio?
                            </span>
                            <div
                                class="mt-2 d-flex align-items-center"
                                *ngFor="let opcao of opcoesSimNaoSuicidio"
                            >
                                <input
                                    formControlName="SuicidioPensou"
                                    type="radio"
                                    [value]="opcao.value"
                                    class="form-input-checkbox"
                                    [checked]="
                                        formGroupEtapa10.get('SuicidioPensou')
                                            .value == opcao.value
                                    "
                                />
                                <label class="form-label-texts-helper-check">{{
                                    opcao.label
                                }}</label>
                            </div>
                        </div>
                    </div>

                    <div
                        class="col-12"
                        *ngIf="
                            formGroupEtapa10.get('SuicidioPensou').value ===
                            1
                        "
                    >
                        <div
                            [ngClass]="{
                                'input-error':
                                    campoinvalido === 'SuicidioUltimoAno'
                            }"
                        >
                            <span class="form-label-texts">
                                Nos últimos 12 meses?
                            </span>
                            <div
                                class="mt-2 d-flex align-items-center"
                                *ngFor="let opcao of opcoesSimNaoSuicidio"
                            >
                                <input
                                    formControlName="SuicidioUltimoAno"
                                    type="radio"
                                    [value]="opcao.value"
                                    class="form-input-checkbox"
                                    [checked]="
                                        formGroupEtapa10.get(
                                            'SuicidioUltimoAno'
                                        ).value == opcao.value
                                    "
                                />
                                <label class="form-label-texts-helper-check">{{
                                    opcao.label
                                }}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="col-12"
                    *ngIf="formGroupEtapa10.get('SuicidioPensou').value === 1"
                >
                    <div
                        [ngClass]="{
                            'input-error': campoinvalido === 'SuicidioTentou'
                        }"
                    >
                        <span class="form-label-texts">
                            Você já tentou suicídio?
                        </span>
                        <div
                            class="mt-2 d-flex align-items-center"
                            *ngFor="let opcao of opcoesSimNaoSuicidio"
                        >
                            <input
                                formControlName="SuicidioTentou"
                                type="radio"
                                [value]="opcao.value"
                                class="form-input-checkbox"
                                [checked]="
                                    formGroupEtapa10.get('SuicidioTentou')
                                        .value == opcao.value
                                "
                            />
                            <label class="form-label-texts-helper-check">{{
                                opcao.label
                            }}</label>
                        </div>
                    </div>
                </div>

                <div class="col-12 mt-3">
                    <hr />
                </div>
                <div class="col-12 mt-3">
                    <span class="form-label-texts">
                        Parabéns, últimas perguntas!
                    </span>
                </div>
                <div class="col-md-12 row">
                    <div class="col-md-6 mt-3">
                        <div
                            [ngClass]="{
                                'input-error':
                                    campoinvalido === 'DistraemAnteriores'
                            }"
                        >
                            <span class="form-label-texts">
                                Novas ideias às vezes me distraem das
                                anteriores.*
                            </span>
                            <div
                                class="mt-2 d-flex align-items-center"
                                *ngFor="let opcao of opcoesSemelhancaComigo"
                            >
                                <input
                                    formControlName="DistraemAnteriores"
                                    type="radio"
                                    [value]="opcao.value"
                                    class="form-input-checkbox"
                                    [checked]="
                                        formGroupEtapa10.get(
                                            'DistraemAnteriores'
                                        ).value == opcao.value
                                    "
                                />
                                <label class="form-label-texts-helper-check">{{
                                    opcao.label
                                }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mt-3">
                        <div
                            [ngClass]="{
                                'input-error': campoinvalido === 'NaoDesanimam'
                            }"
                        >
                            <span class="form-label-texts">
                                Os contratempos não me desanimam.
                            </span>

                            <div
                                class="mt-2 d-flex align-items-center"
                                *ngFor="let opcao of opcoesSemelhancaComigo"
                            >
                                <input
                                    formControlName="NaoDesanimam"
                                    type="radio"
                                    [value]="opcao.value"
                                    class="form-input-checkbox"
                                    [checked]="
                                        formGroupEtapa10.get('NaoDesanimam')
                                            .value == opcao.value
                                    "
                                />
                                <label class="form-label-texts-helper-check">{{
                                    opcao.label
                                }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mt-3">
                        <div
                            [ngClass]="{
                                'input-error':
                                    campoinvalido === 'PerdiInteresse'
                            }"
                        >
                            <span class="form-label-texts">
                                Fiquei obcecado (a) por uma ideia ou projeto por
                                um curto período de tempo, mas depois perdi o
                                interesse.*
                            </span>

                            <div
                                class="mt-2 d-flex align-items-center"
                                *ngFor="let opcao of opcoesSemelhancaComigo"
                            >
                                <input
                                    formControlName="PerdiInteresse"
                                    type="radio"
                                    [value]="opcao.value"
                                    class="form-input-checkbox"
                                    [checked]="
                                        formGroupEtapa10.get('PerdiInteresse')
                                            .value == opcao.value
                                    "
                                />
                                <label class="form-label-texts-helper-check">{{
                                    opcao.label
                                }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mt-3">
                        <div
                            [ngClass]="{
                                'input-error':
                                    campoinvalido === 'MuitoTrabalhador'
                            }"
                        >
                            <span class="form-label-texts">
                                Sou muito trabalhador(a).
                            </span>

                            <div
                                class="mt-2 d-flex align-items-center"
                                *ngFor="let opcao of opcoesSemelhancaComigo"
                            >
                                <input
                                    formControlName="MuitoTrabalhador"
                                    type="radio"
                                    [value]="opcao.value"
                                    class="form-input-checkbox"
                                    [checked]="
                                        formGroupEtapa10.get('MuitoTrabalhador')
                                            .value == opcao.value
                                    "
                                />
                                <label class="form-label-texts-helper-check">{{
                                    opcao.label
                                }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mt-3">
                        <div
                            [ngClass]="{
                                'input-error':
                                    campoinvalido === 'SubstituoOutra'
                            }"
                        >
                            <span class="form-label-texts">
                                Com frequência me proponho uma meta que depois
                                substituo por outra.*
                            </span>

                            <div
                                class="mt-2 d-flex align-items-center"
                                *ngFor="let opcao of opcoesSemelhancaComigo"
                            >
                                <input
                                    formControlName="SubstituoOutra"
                                    type="radio"
                                    [value]="opcao.value"
                                    class="form-input-checkbox"
                                    [checked]="
                                        formGroupEtapa10.get('SubstituoOutra')
                                            .value == opcao.value
                                    "
                                />
                                <label class="form-label-texts-helper-check">{{
                                    opcao.label
                                }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mt-3">
                        <div
                            [ngClass]="{
                                'input-error':
                                    campoinvalido === 'SeremConcluidos'
                            }"
                        >
                            <span class="form-label-texts">
                                Tenho dificuldades em manter a atenção focada em
                                projetos que exigiram mais do que uns poucos
                                meses para serem concluídos.*
                            </span>

                            <div
                                class="mt-2 d-flex align-items-center"
                                *ngFor="let opcao of opcoesSemelhancaComigo"
                            >
                                <input
                                    formControlName="SeremConcluidos"
                                    type="radio"
                                    [value]="opcao.value"
                                    class="form-input-checkbox"
                                    [checked]="
                                        formGroupEtapa10.get('SeremConcluidos')
                                            .value == opcao.value
                                    "
                                />
                                <label class="form-label-texts-helper-check">{{
                                    opcao.label
                                }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mt-3">
                        <div
                            [ngClass]="{
                                'input-error': campoinvalido === 'QueConheco'
                            }"
                        >
                            <span class="form-label-texts">
                                Eu termino tudo o que começo.
                            </span>
                            <div
                                class="mt-2 d-flex align-items-center"
                                *ngFor="let opcao of opcoesSemelhancaComigo"
                            >
                                <input
                                    formControlName="QueConheco"
                                    type="radio"
                                    [value]="opcao.value"
                                    class="form-input-checkbox"
                                    [checked]="
                                        formGroupEtapa10.get('QueConheco')
                                            .value == opcao.value
                                    "
                                />
                                <label class="form-label-texts-helper-check">{{
                                    opcao.label
                                }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mt-3">
                        <div
                            [ngClass]="{
                                'input-error': campoinvalido === 'SouEsforcado'
                            }"
                        >
                            <span class="form-label-texts">
                                Sou esforçado(a)
                            </span>

                            <div
                                class="mt-2 d-flex align-items-center"
                                *ngFor="let opcao of opcoesSemelhancaComigo"
                            >
                                <input
                                    formControlName="SouEsforcado"
                                    type="radio"
                                    [value]="opcao.value"
                                    class="form-input-checkbox"
                                    [checked]="
                                        formGroupEtapa10.get('SouEsforcado')
                                            .value == opcao.value
                                    "
                                />
                                <label class="form-label-texts-helper-check">{{
                                    opcao.label
                                }}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 mt-3">
                    <hr />
                </div>
                <div class="col-12 mt-3">
                    <span class="form-label-texts"> Escala de Bristol </span>
                </div>
                <div class="center col-12 mt-3">
                    <img
                        src="../../../../../assets/img/escala-bristol.png"
                        alt="Escala de Bristol"
                        class="img-fluid"
                    />
                </div>
                <div class="col-md-6 mt-3">
                    <div
                        [ngClass]="{
                            'input-error': campoinvalido === 'EscalaBristol'
                        }"
                    >
                        <span class="form-label-texts">
                            Conforme imagem acima, classifique suas fezes.
                        </span>

                        <select
                            formControlName="EscalaBristol"
                            class="form-control form-input-box"
                        >
                            <option value="" selected="selected">
                                Selecione
                            </option>
                            <option
                                *ngFor="let opcao of opcoesClassificacaoFezes"
                                [ngValue]="opcao.value"
                                [selected]="
                                    opcao.value ===
                                    formGroupEtapa10.get('EscalaBristol').value
                                        ? 'selected'
                                        : ''
                                "
                            >
                                {{ opcao.label }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-12 mt-3">
                    <hr />
                </div>
                <div class="col-12 mt-3">
                    <h4 class="center">AUTORIZAÇÃO DE USO DE DADOS</h4>
                </div>
                <div class="col-12 mt-3">
                    <span class="form-label-texts">
                        Esses dados estão de acordo com a Lei Geral de Proteção
                        de Dados (LGPD). Informamos também que os dados serão
                        anonimizados, de forma que não será possível identificar
                        dados pessoais. Os dados serão utilizados exclusivamente
                        para fins de pesquisa e saúde pública.
                    </span>
                </div>
                <div class="col-12 mt-3">
                    <span class="form-label-texts">
                        Declaro para os devidos fins, que cederei o acesso
                        completo, aos dados de (Anamneses e Avaliações) para
                        serem utilizados em pesquisas orientadas sob o Metodo
                        SAFE.
                    </span>
                </div>
                <div class="col-md-6 mt-3">
                    <div [ngClass]="{'input-error': campoinvalido === 'Termo'}">
                        <span class="form-label-texts">
                            Autoriza o uso das suas informações?
                        </span>

                        <div
                            class="mt-2 d-flex align-items-center"
                            *ngFor="let opcao of opcoesSimNao"
                        >
                            <input
                                formControlName="Termo"
                                type="radio"
                                [value]="opcao.value"
                                class="form-input-checkbox"
                                [checked]="
                                    formGroupEtapa10.get('Termo').value ==
                                    opcao.value
                                "
                            />
                            <label class="form-label-texts-helper-check">{{
                                opcao.label
                            }}</label>
                        </div>
                    </div>
                </div>
                <div class="col-12 mb-2 mt-4" *ngIf="erroAvancarAnamnese">
                    <div class="alert alert-danger" role="alert">
                        {{ erroAvancarAnamnese }}
                    </div>
                </div>

                <div
                    class="col-12 form-group-buttons row"
                    style="margin-left: 10px"
                >
                    <div class="col-md-3 mb-3">
                        <button
                            class="btn btn-secondary btn-block btn-lg form-bnt-voltar"
                            (click)="handleClickFecharModal()"
                        >
                            Fechar
                        </button>
                    </div>
                    <div class="col-md-3 mb-3">
                        <button
                            class="btn btn-secondary btn-block btn-lg form-bnt-voltar"
                            (click)="handleClickVoltarModal()"
                        >
                            Voltar
                        </button>
                    </div>

                    <div
                        class="col-md-6 mb-3"
                        *ngIf="!carregandoAtualizarAnamnese"
                    >
                        <button
                            class="btn btn-primary btn-block btn-sm form-bnt-salvar"
                            (click)="handleClickProximoModal()"
                        >
                            Próximo
                        </button>
                    </div>

                    <div
                        class="col-md-6 mb-3"
                        *ngIf="carregandoAtualizarAnamnese"
                    >
                        <button
                            class="btn btn-primary btn-block btn-sm form-bnt-salvar"
                            (click)="handleClickProximoModal()"
                            disabled
                        >
                            Carregando
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
