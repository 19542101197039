import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {AppService} from '@services/app.service';
import {AnamneseService} from '@services/dashboard/anamnese/anamnese.service';
import {ModalRetaFinalComponent} from '../Modal10-RetaFinal/modal-retaFinal.component';
import {ModalCheckBoxes2Component} from '../Modal8-CheckBoxes2/modal-checkBoxes2.component';
import {Router} from "@angular/router";
import {ModalPercepSaudeComponent} from "@components/dashboard/modais/Modal6-PercepSaude/modal-percepSaude.component";

@Component({
    selector: 'app-modal-checkBoxes3Sono',
    templateUrl: './modal-checkBoxes3Sono.component.html',
    styleUrls: ['./modal-checkBoxes3Sono.component.scss']
})
export class ModalCheckBoxes3SonoComponent implements OnInit {
    erroAvancarAnamnese: string | any = ""
    campoinvalido: string | any = ""
    formGroupEtapa9: FormGroup
    carregandoAtualizarAnamnese: boolean = false
    public desativarCampos: boolean = false

    opcoesCochilar = [{
        label: "Nunca cochilaria", value: 0
    }, {
        label: "Pequena probabilidade de cochilar", value: 1
    }, {
        label: "Probabilidade média de cochilar", value: 2
    }, {
        label: "Grande probabilidade de cochilar", value: 3
    },]

    opcoesExtremo = [{
        label: "Nada", value: 1
    }, {
        label: "Muito pouco", value: 2
    }, {
        label: "Mais ou menos", value: 3
    }, {
        label: "Bastante", value: 4
    }, {
        label: "Extremamente", value: 5
    },]

    opcoesSimNao = [{
        label: "Sim", value: true
    }, {
        label: "Não", value: false
    }]

    opcoesFrequenciaSemanal = [{
        label: "Mais do que uma vez por semana", value: 1
    }, {
        label: "Uma vez por semana", value: 2
    }, {
        label: "Duas a três vezes por mês", value: 3
    }, {
        label: "Algumas vezes por ano", value: 4
    }, {
        label: "Uma vez por ano mais ou menos", value: 5
    }, {
        label: "Nunca", value: 6
    },]

    opcoesFrequenciaDiaria = [{
        label: "Mais do que uma vez ao dia", value: 1
    }, {
        label: "Diariamente", value: 2
    }, {
        label: "Duas ou mais vezes por semana", value: 3
    }, {
        label: "Uma vez por semana", value: 4
    }, {
        label: "Poucas vezes por mês", value: 5
    }, {
        label: "Raramente ou nunca", value: 6
    },]

    opcoesVeracidade = [{
        label: "Totalmente verdade para mim", value: 1
    }, {
        label: "Em geral é verdade", value: 2
    }, {
        label: "Nunca", value: 3
    }, {
        label: "Em geral não é verdade", value: 4
    }, {
        label: "Não é verdade", value: 5
    },]

    constructor(private appService: AppService, private anamneseService: AnamneseService, private router: Router) {
    }

    ngOnInit(): void {
        this.desativarCampos = this.router.url.includes('acesso-avaliador')

        const anamnese = this.anamneseService.getAnamnese()

        this.formGroupEtapa9 = new FormGroup({
            NumeroEtapa: new FormControl({value: anamnese?.Etapa9?.NumeroEtapa, disabled: this.desativarCampos}),
            AnamneseID: new FormControl({value: anamnese?.Etapa9?.AnamneseID, disabled: this.desativarCampos}),
            SentadoLendo: new FormControl({value: anamnese?.Etapa9?.SentadoLendo, disabled: this.desativarCampos}),
            AssistindoTv: new FormControl({value: anamnese?.Etapa9?.AssistindoTv, disabled: this.desativarCampos}),
            QuietoLugarPublico: new FormControl({
                value: anamnese?.Etapa9?.QuietoLugarPublico,
                disabled: this.desativarCampos
            }),
            AndandoCarro: new FormControl({value: anamnese?.Etapa9?.AndandoCarro, disabled: this.desativarCampos}),
            QuietoAlmoco: new FormControl({value: anamnese?.Etapa9?.QuietoAlmoco, disabled: this.desativarCampos}),
            ParadoCarro: new FormControl({value: anamnese?.Etapa9?.ParadoCarro, disabled: this.desativarCampos}),
            SentadoConversando: new FormControl({
                value: anamnese?.Etapa9?.SentadoConversando,
                disabled: this.desativarCampos
            }),
            DeitadoDescanso: new FormControl({
                value: anamnese?.Etapa9?.DeitadoDescanso,
                disabled: this.desativarCampos
            }),
            SentidoVida: new FormControl({value: anamnese?.Etapa9?.SentidoVida, disabled: this.desativarCampos}),
            MedidaSentidoVida: new FormControl({
                value: anamnese?.Etapa9?.MedidaSentidoVida,
                disabled: this.desativarCampos
            }),
            ForcaDificuldade: new FormControl({
                value: anamnese?.Etapa9?.ForcaDificuldade,
                disabled: this.desativarCampos
            }),
            EntenderDificuldades: new FormControl({
                value: anamnese?.Etapa9?.EntenderDificuldades,
                disabled: this.desativarCampos
            }),
            TemReligiao: new FormControl({value: anamnese?.Etapa9?.TemReligiao, disabled: this.desativarCampos}),
            Religiao: new FormControl({value: anamnese?.Etapa9?.Religiao, disabled: this.desativarCampos}),
            FrequentaSemanalmente: new FormControl({
                value: anamnese?.Etapa9?.FrequentaSemanalmente,
                disabled: this.desativarCampos
            }),
            LocalFrequenta: new FormControl({value: anamnese?.Etapa9?.LocalFrequenta, disabled: this.desativarCampos}),
            FrequentaVezes: new FormControl({value: anamnese?.Etapa9?.FrequentaVezes, disabled: this.desativarCampos}),
            EspiritualidadeFrequenciaTemplo: new FormControl({
                value: anamnese?.Etapa9?.EspiritualidadeFrequenciaTemplo,
                disabled: this.desativarCampos
            }),
            EspiritualidadeLeituraFrequencia: new FormControl({
                value: anamnese?.Etapa9?.EspiritualidadeLeituraFrequencia,
                disabled: this.desativarCampos
            }),
            EspiritualidadePresencaDeus: new FormControl({
                value: anamnese?.Etapa9?.EspiritualidadePresencaDeus,
                disabled: this.desativarCampos
            }),
            EspiritualidadeCrencasReligiosas: new FormControl({
                value: anamnese?.Etapa9?.EspiritualidadeCrencasReligiosas,
                disabled: this.desativarCampos
            }),
            EspiritualidadeEsforcoViver: new FormControl({
                value: anamnese?.Etapa9?.EspiritualidadeEsforcoViver,
                disabled: this.desativarCampos
            }),
            DataInicio: new FormControl(new Date()),
            DataFim: new FormControl(null)
        })
    }

    handleClickFecharModal() {
        this.appService.closeModal()
    }

    handleClickVoltarModal() {
        this.appService.closeModal()
        this.appService.openModal(ModalCheckBoxes2Component, {size: 'lg', backdrop: false})
    }


    handleClickProximoModal() {

        // caso os campos esteja desativados,
        // significa que é o professor acessando a tela
        // E ele só pode visualizar as informacoes
        // Logo, a gente nao atualiza a anamnese, apenas
        // Redireciona para a nova tela
        if (this.desativarCampos) {
            this.appService.closeModal()
            this.appService.openModal(ModalRetaFinalComponent)
            return
        }

        const anamnese = this.anamneseService.getAnamnese()
        const classeContexto = this

        classeContexto.carregandoAtualizarAnamnese = true

        const novaAnamneseAtualizada = {
            ...anamnese, Etapa9: {
                ...anamnese?.Etapa9, ...this.formGroupEtapa9.getRawValue(), DataFim: new Date(),
            }
        }

        // primeiro, validamos a anamnese, para ver se os campos obrigatórios foram todos preenchidos
        // Caso estejam preenchidos, deixamos avançar
        this.anamneseService.validarAnamnese(novaAnamneseAtualizada).subscribe({
            next(response) {

                // verifico se há etapas inválidas (endpoint retorna 200 mesmo pra dados inválidos)
                // @ts-ignore
                if (typeof response?.etapaInvalida?.mensagem == 'string' && response?.etapaInvalida?.etapa === 9) {
                    // @ts-ignore
                    classeContexto.erroAvancarAnamnese = response?.etapaInvalida?.mensagem
                     // @ts-ignore
                     classeContexto.campoinvalido = response?.etapaInvalida?.campo;
                    classeContexto.carregandoAtualizarAnamnese = false
                    return
                }

                // atualiza a anamnese se tudo estiver ok
                classeContexto.anamneseService.putAnamnese(novaAnamneseAtualizada).subscribe({
                    next(response2) {

                        classeContexto.carregandoAtualizarAnamnese = false
                        classeContexto.anamneseService.setAnamnese(response2)
                        classeContexto.appService.closeModal()
                        classeContexto.appService.openModal(ModalRetaFinalComponent)
                    }, error(response) {
                        classeContexto.carregandoAtualizarAnamnese = false
                        if (typeof response?.error?.Message == 'string') {
                            classeContexto.erroAvancarAnamnese = response?.error?.Message
                        } else {
                            classeContexto.erroAvancarAnamnese = "Ocorreu um erro ao atualizar a anamnese"
                        }
                    }
                })

            }, error(erro) {
                classeContexto.carregandoAtualizarAnamnese = false
                if (typeof erro?.Message == 'string') {
                    classeContexto.erroAvancarAnamnese = erro?.Message
                } else {
                    classeContexto.erroAvancarAnamnese = "Ocorreu um erro ao validar as informações preenchidas"
                }
            }
        })
    }
}
